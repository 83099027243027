<template>
	<v-dialog
		v-model="dialog"
		width="900"
	>
		<v-card class="elevation-12">
			<!-- Хэдер -->
			<v-toolbar dark flat color="primary">
				<v-toolbar-title>
					Редактирование
				</v-toolbar-title>
				<v-spacer />
				<v-btn color="dark" @click="closeModal" icon>
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-toolbar>

			
			<v-card-text class="mt-3">
        <v-form ref="form">
          <v-row v-if="data.id">
            <v-col cols="6">
              <span class="subtitle-2 font-weight-light grey--text">ФИО руководителя</span>
              <v-text-field
                v-model.trim="fio"
                class="border-all mt-2"
                solo
                dense
                hide-details
                flat
                required
                placeholder="Введите ФИО"
                :rules="[maxLengthRule, maxWordsRule]"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <span class="subtitle-2 font-weight-light grey--text">Номер телефона</span>
              <v-text-field
                v-model="data.phone"
                v-mask="'+7-###-###-##-##'"
                class="border-all mt-2"
                solo
                dense
                hide-details
                flat
                placeholder="+7-"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
        <v-row v-if="data.company_type.sys_name === 'sc'">
          <v-col cols="6">
            <span class="subtitle-2 font-weight-light grey--text">Адрес</span>
            <template v-for="(item, i) in data.company_houses">
              <div class="select-container" :key="i">
                <v-select
                  v-model="item.id"
                  append-icon=""
                  class="border-all mt-2 pointer-none"
                  solo
                  dense
                  hide-details
                  flat
                  :items="houses"
                  item-text="apartment_address"
                  item-value="id"
                ></v-select>
                <v-btn
                  @click="removeHouse(i)"
                  icon
                  color="red"
                  class="delete-btn"
                >
                <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </template>
            <template v-for="(newHouse, index) in newHouses">
              <Autocomplete
                v-model="newHouse.id"
                :key="'dynamic-house-' + index"
                class="border-all mt-2"
                solo
                dense
                hide-details
                flat
                item-text="apartment_address"
                item-value="id"
                :items="houses"
                @change="changeHouse"
              ></Autocomplete>
            </template>
          </v-col>
          <v-col cols="6">
            <span class="subtitle-2 font-weight-light grey--text">Категории</span>
            <template v-for="(item, i) in data.company_houses">
              <Autocomplete
                v-model="housesCategories[item.id]"
                @change="updateCategories($event, item.id)"
                :key="i"
                placeholder="Выберите категорию"
                class="border-all pt-0 mt-2"
                hide-details
                flat
                solo
                dense
                deletable-chips
                select-all="true"
                multiple
                :items="catalog.request_categories"
                item-text="name"
                item-value="id"
              >
              </Autocomplete>
            </template>
            <template v-for="(newHouse, index) in newHouses">
              <v-autocomplete
                v-model="newHouse.selectedCategoryIds"
                :key="'dynamic-categories-' + index"
                placeholder="Выберите категории"
                class="border-all pt-0 category mt-2"
                hide-details
                flat
                solo
                dense
                clearable
                deletable-chips
                multiple
                item-text="name"
                item-value="id"
                :items="catalog.request_categories"
              >
                <template v-slot:item="data">
                  <template v-if="isCategoryDisabled(data.item.id)">
                    <v-list-item @click="preventClick">
                      <v-icon small>mdi-cancel</v-icon>
                      <span class="ml-3 ft-14">
                        {{ data.item.name }}
                        <span class="grey--text">{{ getCompanyName(data.item.id) }}</span>
                      </span>
                    </v-list-item>
                  </template>
                  <template v-else>
                    <v-icon v-if="newHouse.selectedCategoryIds ? newHouse.selectedCategoryIds.includes(data.item.id) : false">mdi-checkbox-marked</v-icon>
                    <span v-else class="d-flex">
                      <span class="checkbox-square"></span>
                    </span>
                    <span class="ml-3 ft-14">{{data.item.name}}</span>
                  </template>
                </template>
              </v-autocomplete>
              <!-- <Autocomplete
                v-model="newHouse.selectedCategoryIds"
                :key="'dynamic-categories-' + index"
                placeholder="Выберите категории"
                class="border-all pt-0 category mt-2"
                hide-details
                flat
                solo
                dense
                clearable
                deletable-chips
                multiple
                item-text="name"
                item-value="id"
                :items="catalog.request_categories"
              >
              </Autocomplete> -->
            </template>
          </v-col>
          <v-btn
            class="ml-3"
            color="primary"
            @click="addDynamicHouse"
            small
            depressed
          >
            <v-icon class="mr-1" small>mdi-plus</v-icon>
            <span class="body-2 font-weight-light">Добавить дом</span>
          </v-btn>
        </v-row>
        
			</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="closeModal"
          >
            Отмена
          </v-btn>
          <v-btn
            @click="editKsk"
            color="green darken-1"
            text
            :loading="isLoading"
          >
            Сохранить
          </v-btn>
      </v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { getFullname } from "@/helpers/helpers";
export default {
	name: "Register",
  props: ['kskData'],
	data() {
		return {
			alert: null,
      dialog: true,
			loading: false,
			isLoading: false,
			newHouses: [],
			houseCategories: [],
      fio: '',
      copyPhone: '',
      data: {
        id: '',
        phone: '',
        user: '',
        company_type: '',
        company_houses: [],
        company_house_category: []
      },
      maxLengthRule: value => (value && value.length <= 50) || 'Отчество должно быть не более 50 символов',
      maxWordsRule: value => (value && value.split(' ').filter(word => word).length <= 3) || 'ФИО должно содержать не более 3 слов',
		};
	},
	computed: {
		...mapGetters({
			catalog: "catalog/GET_CATALOG",
			organization_types: "ksk/GET_ORGANIZATION_TYPES",
      houses: "dispatcher/GET_HOUSES",
		}),
    housesCategories() {
      let categoriesByHouse = {};
      this.data.company_houses.forEach((house) => {
          let houseCategories = this.data.company_house_category
              .filter((item) => item.house.id === house.id)
              .map((item) => item.category);
          categoriesByHouse[house.id] = houseCategories;
      });
      console.log('categoriesByHouse', categoriesByHouse)
      return categoriesByHouse;
    }
	},
	methods: {
    isSelected(item) {
      if (this.newHouse.selectedCategoryIds) {
        return this.newHouse.selectedCategoryIds.includes(item.id);
      }
    },
    preventClick(event) {
      event.stopPropagation();
    },
    async changeHouse(id) {
      console.log('event', id)
      try {
        this.houseCategories = await this.$api.ksk.load_houses_category(id)
        console.log('this.houseCategories', this.houseCategories)
      } catch (error) {
        console.log('err', error)
      }
    },
    isCategoryDisabled(categoryId) {
      return this.houseCategories.some(
        (houseCategory) => houseCategory.category.id === categoryId
      );
    },
    getCompanyName(categoryId) {
      const category = this.houseCategories.find(
        (houseCategory) => houseCategory.category.id === categoryId
      );
      return category ? category.company.name : '';
    },
    updateCategories(selectedCategoryIds, houseId) {
      const houseToUpdate = this.data.company_houses.find(house => house.id === houseId);
      console.log('houseToUpdate', houseToUpdate)
      if (houseToUpdate) {
        const currentCategories = this.data.company_house_category.filter(item => item.house.id === houseId);
        console.log("currentCategories", currentCategories)
        // Находим новые категории, которые были выбраны, но отсутствуют в текущих категориях
        const newCategories = selectedCategoryIds.filter(categoryId => !currentCategories.some(item => item.category.id === categoryId));
        console.log("newCategories", newCategories)
        // Находим категории, которые присутствуют в текущих категориях, но не были выбраны
        const removedCategories = currentCategories.filter(item => !selectedCategoryIds.includes(item.category.id));
        // Добавляем новые категории
        newCategories.forEach(categoryId => {
          const category = this.data.company_category.find(cat => cat.id === categoryId);
          if (!category) {
            this.data.company_house_category.push({
              house: houseToUpdate,
              category: {
                id: categoryId
              }
            });
          }
        });

        // Удаляем категории, которые были удалены
        removedCategories.forEach(removedCategory => {
          const index = this.data.company_house_category.findIndex(item => item === removedCategory);
          if (index !== -1) {
            this.data.company_house_category.splice(index, 1);
          }
        });
      }
    },
    addDynamicHouse() {
      this.newHouses.push({
        id: null,
        categories: [],
      });
    },
    async editKsk() {
      if (!this.$refs.form.validate()) {
				return this.$root.snackbar.show({ color: "red", message: "Некорректное заполнение поля" });
      }
      let data = {}
      let dataEdit = {}
      let showFinishAlert = true
      this.fio = this.fio.replace(/\s\s+/g, " ");

      const fio = this.fio.split(" ");

      if (!fio) {
        return this.$root.snackbar.show({ color: "red", message: "Поле ФИО не должно быть пустым" });
      }
      this.isLoading = true
      if(this.data.company_type.sys_name === 'sc') {
        const housesIds = [
          ...this.data.company_houses.map(house => house.id),
          ...this.newHouses.filter(house => house.id).map(house => house.id)
        ];

        const allCategories = this.data.company_house_category.concat(this.newHouses.flatMap(house => house.selectedCategoryIds.map(id => ({
          category: { id },
          house: { id: house.id }
        }))));
        
        const categoryIds = [...new Set(allCategories.map(item => item.category.id))];

        const housesCategory = allCategories.map(item => ({
          house_id: item.house.id,
          category_id: item.category.id
        }));

        data = {
          houses_ids: housesIds,
          category_ids: categoryIds,
          houses_category: housesCategory,
          director_surname: fio[0],
          director_name: "", 
          director_patronymic: "",
          phone: this.data.phone,
          _method: "patch"
        };
        
        // Редактирование директора УК
        const kskCategories = categoryIds.map(id => ({
          ksk_id: this.data.id,
          category_id: id
        }));
        const kskHouses = housesIds.map(id => ({
          ksk_id: this.data.id,
          house_id: id
        }));

        dataEdit = {
          ksk_houses_ids: kskHouses,
          ksk_category_ids: kskCategories,
          surname: fio[0],
          name: "", 
          patronymic: "",
          _method: 'patch'
        }

      } else {
        dataEdit = {
          surname: fio[0],
          name: "", 
          patronymic: "",
          _method: 'patch'
        }

        data = {
          director_surname: fio[0],
          director_name: "", 
          director_patronymic: "",
          phone: this.data.phone,
          _method: "patch"
        };
      }
      if (fio.length > 1) {
        dataEdit.name = fio[1];
      }
      if (fio.length > 2) {
        dataEdit.patronymic = fio[2];
      }
      if (fio.length > 1) {
        data.director_name = fio[1];
      }
      if (fio.length > 2) {
        data.director_patronymic = fio[2];
      }
      console.log('phone', this.data.phone)
      console.log('copyPhone', this.copyPhone)
      if(this.copyPhone !== this.data.phone) {
        const res = await this.$api.user.user_exists({phone: this.data.phone});
        if(res.exists) {
          const resp = await this.$api.ksk.director_change({user_id: res.user_id}, this.data.id);
          console.log('resp', resp)
          if(resp?.color === 'error') {
            this.isLoading = false
            return this.$root.snackbar.show({ color: "red", message: resp.error.response.data.message || "Произошла ошибка", timer: 10000 });
          } else {
            this.isLoading = false
            this.$root.snackbar.show({ color: "success", message: resp.message, timer: 10000 });
            showFinishAlert = false
            this.data.user.id = res.user_id
          }
        } else {
          console.log('not exists', res)
          const response = await this.$api.user.user_registration({phone: this.data.phone});
          console.log('response', response)
          const resp = await this.$api.ksk.director_change({user_id: response.user.id}, this.data.id);
          console.log('resp', resp)
          this.$root.snackbar.show({ color: "success", message: resp.message, timer: 10000 });
          this.data.user.id = response.user.id
          showFinishAlert = false
          this.isLoading = false
        }
      } else {
        delete data.phone
      }
      try {
        const res = await this.$api.ksk.edit_ksk(data, this.data.id);
        console.log('res', res)
        if(res?.color == 'error') {
          this.$root.snackbar.show({ color: "red", message: res.message || "Произошла ошибка", timer: 10000 });
          this.isLoading = false
        } else {
          await this.$api.ksk.save_employee(dataEdit, this.data.user.id, this.data.id);
          if(showFinishAlert) {
            this.$root.snackbar.show({ color: "success", message: "Сохранено успешно", timer: 10000 });
          }
          setTimeout(() => this.closeModal('update'), 500);
          this.isLoading = false
        }
      } catch (error) {
        this.isLoading = false
        this.$root.snackbar.show({ color: "red", message: error.message || "Произошла ошибка", timer: 10000 });
        console.error('Error editing ksk:', error);
      }
    },
    removeHouse(index) {
      const houseToRemove = this.data.company_houses[index];
      if (houseToRemove) {
        // Удаляем дом из массива company_houses
        this.data.company_houses.splice(index, 1);
        
        // Удаляем все категории, связанные с этим домом, если они не используются в других домах
        this.data.company_house_category = this.data.company_house_category.filter(item => item.house.id !== houseToRemove.id);
        
        // Обновляем список категорий, проверяя, не используется ли категория в других домах
        const remainingCategoryIds = new Set(this.data.company_house_category.map(item => item.category.id));
        this.data.company_category = this.data.company_category.filter(category => remainingCategoryIds.has(category.id));
      }
    },
    closeModal(action) {
			this.dialog = false;
			this.$emit("close-modal");
      
      if(action === 'update') {
        console.log('action', action)
        this.$emit("update-data");
      }
		},
	},
	created() {
    this.data = this.kskData
    this.copyPhone = this.kskData.phone
    this.fio = this.getFullname(this.data.user, true, true);
		// this.$store.dispatch("ksk/load_organization_types");
	},
};
</script>

<style>
  .chips-custom .v-chip--select {
	background: #F5F5F5 !important;
	border: 1px solid #E4E7EA !important;
	border-radius: 4px !important;
	padding: 0px 8px 0px 8px !important;
}
.pointer-none {
	pointer-events: none;
}
.chips-custom .v-input__slot {
	padding: 0 !important;
}
.select-container:hover .delete-btn {
  display: block;
}
.select-container {
  position: relative;
}
.delete-btn {
  display: none;
  position: absolute;
	top: 0px;
	right: 0px;
}
.category .v-text-field.v-input--dense:not(.v-text-field--outlined) .v-text-field__prefix, .v-text-field.v-input--dense:not(.v-text-field--outlined) .v-text-field__suffix, .v-text-field.v-input--dense:not(.v-text-field--outlined) input {
  padding: 10px !important;
}
.chips-custom .v-select__selections {
  min-height: 33px !important;
}
</style>

<style scoped>
.checkbox-square {
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 1px solid #B5B9BD;
  border-radius: 2px;
  margin-left: 2px;
  margin-right: 2px;
}
.ft-14 {
  font-size: 14px !important;
}
</style>